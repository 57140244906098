import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
  center: {
    lat: 52.48,
    lng: -1.90,
  },
  zoom: 16,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
  <div style={{ height: '60vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyD3Jup0W9OOsR9pCbVpftv5-5I_BjjpVXU' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent
        lat={52.4808236}
        lng={-1.9044452}
        text={'Edmund House'}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
