import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Card from "../components/card"
import GoogleMap from "../components/googleMap"
import Form from "../components/form"
const desription =`Give us a call on weekdays between 9am to 5pm, or pop into one of our offices. Our online services is also available 24 hours a day, seven days a week.`

const email= `Feel free to contact is on admin@pomegenix.com`
const phone = `Give us a call on weekdays between 9am to 5pm. Tel: 01217518990`
const walk=`You can pop into any of our close offices for a chat and we will be happy to host you.`
const Contact = () => {
  return (
    <>
      <Layout>
        <Hero text="Contact us now" info="There are many ways to contact us."/>
        <SEO title="Email, Phone or Pop Over to discuss Business and Technology" description={desription} />
        <GoogleMap />
        <Form />

          <div className="container formpage" style={{paddingBottom:"50px"}}>
            <div className="row">
              <Card fontawsome="fas fa-envelope-open-text" text={email}/>
              <Card fontawsome="fas fa-phone" text={phone}/>
              <Card fontawsome="fas fa-walking" text={walk} />
            </div>
          </div>
      </Layout>
    </>
  )
}

export default Contact
