import React from "react"

const Form = () => {
  return (
    <>
      <div className="site-section bg-light formpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-5">
              <form action="/thankyou" method="post">
                <div className="form-group row">
                  <div className="col-md-6 mb-4 mb-lg-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email address"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-12">
                    <textarea
                      name=""
                      id=""
                      className="form-control"
                      placeholder="Write your message."
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-6 mr-auto">
                    <input
                      type="submit"
                      className="btn btn-block btn-primary text-white py-3 px-5"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4 ml-auto">
              <div className="bg-white p-3 p-md-5">
                <h3 className="text-black mb-4">Contact Info</h3>
                <ul className="list-unstyled footer-link">
                  <li className="d-block mb-3">
                    <span className="d-block text-black">Address:</span>
                    <span>Edmund House</span>
                    <br></br>
                    <span>
                      312-22 Newhall Street, Birmingham, West Midlands,
                    </span>
                    <br></br>
                    <span>B3 3AS</span>
                  </li>
                  <li className="d-block mb-3">
                    <span className="d-block text-black">Phone:</span>
                    <span>0121 751 8990</span>
                  </li>
                  <li className="d-block mb-3">
                    <span className="d-block text-black">Email:</span>
                    <span>admin@pomegenix.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form
